body { background: white; }

.blue-bar {
  background-color: #F46D14; //orange
}

.navbar-default {
  border-bottom: 5px solid #E5E5E5;

  .navbar-nav {
    li > a {
      color: #333;
    }

    li.active > a {
      background-color: #333;
    }

    li.active > a:hover {
      background: #333;
    }
  }
}

.footer {
  border-top: 1px solid #E5E5E5;
}

h1, h2, h3 {
  color: #333;
}
